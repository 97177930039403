
/* eslint-disable @typescript-eslint/camelcase */
import { PropType } from "vue";
import { Options, Vue, prop } from "vue-class-component";
import SelectSearch from "@/app/ui/components/select-search-origin-destination/index.vue";
import SelectSearchV2 from "@/app/ui/components/select-search/index.vue";
import InputDimension from "@/app/ui/components/input-dimension/index.vue";
import InputAdornment from "@/app/ui/components/input-adornment/index.vue";
import debounce from "lodash/debounce";
import { ClientController } from "../../controllers/ClientController";
import { CommodityController } from "../../controllers/CommodityController";
import { SHIPMENT_CEK_TARIF } from "@/app/infrastructures/misc/RolePermission";
import { AccountController } from "../../controllers/AccountController";
import {
  checkRolePermission,
  formatInputMoney,
  formatInputNumberOnlyV2,
  IS_SENDER_ACCOUNT,
  parseInputFloatToFloat,
} from "@/app/infrastructures/misc/Utils";
import { RequestListCommodity } from "@/data/payload/api/CommodityApiRequest";
import { TariffController } from "../../controllers/TariffController";
import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";
import { LocationController } from "../../controllers/LocationController";

class Props {
  isLoadin = prop<boolean>({
    default: false,
    type: Boolean
  });
  isDisabled = prop<boolean>({
    default: true,
    type: Boolean
  });
  isReset = prop<boolean>({
    default: false,
    type: Boolean
  });
  labelButtonRight = prop<string>({
    default: "Download Tarif",
    type: String
  });
  // origin
  placeholderOrigin = prop<string>({
    default: "",
    type: String
  });
  searchValueOrigin = prop<string>({
    default: "",
    type: String
  });
  selectSearchDataOrigin = prop<any[]>({
    default: [],
    type: Array
  });
  onSelectSearchOrigin = prop<Function>({
    default: Function as PropType<() => void>,
    type: Function
  });
  onOpenSelectSearchOrigin = prop<Function>({
    default: Function as PropType<() => void>,
    type: Function
  });
  onCloseSelectSearchOrigin = prop<Function>({
    default: Function as PropType<() => void>,
    type: Function
  });
  onChooseSelectSearchOrigin = prop<Function>({
    default: Function as PropType<() => void>,
    type: Function
  });
  statusSelectSearchOrigin = prop<boolean>({
    default: false,
    type: Boolean
  });
  selectSearchOriginNotFound = prop<boolean>({
    default: false,
    type: Boolean
  });
  // destination
  placeholderDestination = prop<string>({
    default: "",
    type: String
  });
  searchValueDestination = prop<string>({
    default: "",
    type: String
  });
  selectSearchDataDestination = prop<any[]>({
    default: [],
    type: Array
  });
  onSelectSearchDestination = prop<Function>({
    default: Function as PropType<() => void>,
    type: Function
  });
  onOpenSelectSearchDestination = prop<Function>({
    default: Function as PropType<() => void>,
    type: Function
  });
  onCloseSelectSearchDestination = prop<Function>({
    default: Function as PropType<() => void>,
    type: Function
  });
  onChooseSelectSearchDestination = prop<Function>({
    default: Function as PropType<() => void>,
    type: Function
  });
  statusSelectSearchDestination = prop<boolean>({
    default: false,
    type: Boolean
  });
  selectSearchDestinationNotFound = prop<boolean>({
    default: false,
    type: Boolean
  });
  // product
  valueProduct = prop<string>({
    default: "",
    type: String
  });
  productSelect = prop<boolean>({
    default: false,
    type: Boolean
  });
  productData = prop<any[]>({
    default: [{ name: "", value: "" }],
    type: Array
  });
  onOpenProductSelect = prop<Function>({
    default: Function as PropType<() => void>,
    type: Function
  });
  onCloseProductSelect = prop<Function>({
    default: Function as PropType<() => void>,
    type: Function
  });
  onSelectProduct = prop<Function>({
    default: Function as PropType<() => void>,
    type: Function
  });
  // client
  clientValue = prop<string>({
    default: ""
  });
  // commodity
  valueCommodity = prop<string>({
    default: ""
  });
  // berat/paket
  weight = prop<string>({
    default: ""
  });
  // dimension
  length = prop<string>({
    default: ""
  });
  width = prop<string>({
    default: ""
  });
  height = prop<string>({
    default: ""
  });
  price = prop<string>({
    default: ""
  });
}
@Options({
  components: {
    SelectSearch,
    InputDimension,
    InputAdornment,
    SelectSearchV2,
  },
  emits: ["onButtonRight", "filter", "reset", "onToggleShowFilter"],
})
export default class Header extends Vue.with(Props) {
  get hasSlotData() {
    return !!this.$slots.default;
  }

  get detailDistrict() {
    return LocationController.districtDetailTariff;
  }

  get isInternal() {
    return AccountController.accountData.isInternalAccount;
  }

  get isSenderAccount() {
    return IS_SENDER_ACCOUNT;
  }

  get isPosAccount() {
    return AccountController.accountData.isPosAccount;
  }
  
  get isAccountCountryOutsideID() {
    return AccountController.accountData.account_type_detail.countryCode.toLowerCase() !== 'id';
  }

  // permission
  get isFilterAble() {
    return checkRolePermission(SHIPMENT_CEK_TARIF.FILTER);
  }
  get isShowListClient() {
    if (AccountController.accountData.account_type.toLowerCase() === "client") {
      this.form.client = AccountController.accountData.account_type_detail.company_name;
      return true;
    }
    return false;
  }

  mounted() {
    this.$emit("onToggleShowFilter", this.isFilterShow);
  }

  // client/partner
  onChangeClient(value: any) {
    this.form.client = value;
    this.$emit("update:clientValue", value);
  }
  onGetListPartner(search: string) {
    ClientController._onGetListPartner({
      page: 1,
      limit: 10,
      search: search,
      partnerType: "client",
    });
  }

  fetchClientPartner = debounce((search: string) => {
    this.onGetListPartner(search);
  }, 250);

  get clientPartnerData() {
    return ClientController.clientPartnerData;
  }

  get isLoadingClientPartner() {
    return ClientController.isLoading;
  }

  get isInternalOutsideID() {
    return this.isInternal && this.detailDistrict.countryCode.toLowerCase() === 'my';
  }

  // commodity
  commodityValueSearch = "";
  onChangeCommodity(value: any) {
    this.form.commodity = value;
    this.$emit("update:valueCommodity", value.commodity_id);
  }
  onGetCommodityList(search: string) {
    CommodityController.getCommodityList(
      new RequestListCommodity({
        search: search,
      })
    );
  }
  fetchCommodity = debounce((search: string) => {
    this.commodityValueSearch = search;
    this.onGetCommodityList(search);
  }, 250);

  get commodityList() {
    const data = CommodityController.commodities.data
    .filter(key => this.isAccountCountryOutsideID || (this.isInternalOutsideID && this.searchValueOrigin) ? !!key.commodity_name_en : true)
    .map((key)=> ({...key, commodity_name: this.isAccountCountryOutsideID || (this.isInternalOutsideID && this.searchValueOrigin) ? key.commodity_name_en : key.commodity_name}))
    if (this.commodityValueSearch) return data;
    else return [{ commodity_name: "All Commodity", commodity_id: "all" }, ...data];
  }

  get loadingCommodity() {
    return CommodityController.isLoading;
  }

  // current routes
  get currentRoutes() {
    return this.$route?.meta.name;
  }

  // show filter
  isFilterShow = true;
  setFilterShow(value: boolean) {
    this.isFilterShow = value;
    this.$emit("onToggleShowFilter", value);
  }

  get commodityGeneralOthersId() {
    return FlagsMedusa.config_commodity_general_others_id.getValue();
  }

  get commodityGeneralOthersName() {
    return FlagsMedusa.config_commodity_general_others_name.getValue();
  }
  // misc variable
  form = {
    client: "",
    commodity: {
      commodity_name: this.commodityGeneralOthersName,
      commodity_id: this.commodityGeneralOthersId,
    },
    weight: 1,
    length: 30,
    width: 20,
    height: 10,
    price: "",
  };

  // format input price
  formatInputPrice(value: string) {
    return formatInputMoney(formatInputNumberOnlyV2(value));
  }

  onChangePrice(value: string) {
    this.$emit("update:price", parseInputFloatToFloat(value));
  }

  // weight
  onChangeWeight(value: any) {
    this.$emit("update:weight", value);
  }

  // dimension
  onChangeDimension({ length, width, height }: any) {
    this.$emit("update:length", length);
    this.$emit("update:width", width);
    this.$emit("update:height", height);
  }

  onReset() {
    this.$emit("reset");
  }

  get isLoading() {
    return TariffController.isLoading;
  }
  onFilter() {
    this.$emit("filter");
  }

  onButtonRight() {
    this.$emit("onButtonRight");
  }
}
